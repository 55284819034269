const { resolve } = require('path');
const NextI18Next = require('next-i18next').default;
const { localeSubpaths } = require('next/config').default().publicRuntimeConfig;

const localeSubpathVariations = {
  none: {},
  foreign: {
    ja: 'ja',
  },
  all: {
    ja: 'ja',
    en: 'en',
  },
};

module.exports = new NextI18Next({
  defaultLanguage: 'ja',
  otherLanguages: ['en'],
  localeSubpaths: localeSubpathVariations[localeSubpaths],
  localePath: resolve('./public/static/locales'),
});
